<template>
    <div class="list-view">
        <page-title>
            <el-upload :action="uploadUrl" :on-progress="handleProgress" :auto-upload="true" :show-file-list="false"
                :headers="headers" :on-success="uploadSuc">
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
            <el-button @click="addRow" type="primary" icon="el-icon-plus" :disabled="fetchingData || downloading"
                class="filter-item">
                新增
            </el-button>
            <el-button @click="download" icon="el-icon-upload2" :loading="downloading" :disabled="fetchingData"
                class="filter-item">
                导出
            </el-button>
        </page-title>
        <div class="filters-container">
            <created-at-picker v-model="createdAt" @input="getData" name="创建"></created-at-picker>
            <el-input placeholder="搜索..." v-model="search" clearable class="filter-item search"
                @keyup.enter.native="getData">
                <el-button @click="getData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table :data="tableData" row-key="id" ref="table" header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell" row-class-name="table-row" cell-class-name="table-cell"
            :height="tableHeight" v-loading="fetchingData">
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="phone" label="手机号码"> </el-table-column>
            <el-table-column prop="itemId" label="凭证类型">
                <template slot-scope="{ row }">
                    <span v-if="row.itemId == 0">永久性优先购凭证</span>
                    <span v-if="row.itemId == 1">消耗性全局优先购凭证</span>
                    <span v-if="row.itemId == 2">消耗性指定藏品优先购凭证</span>
                </template>
            </el-table-column>
            <el-table-column prop="advancePurchaseTime" label="优先时间（h）"> </el-table-column>
            <el-table-column prop="advancePurchaseCount" label="优先次数"> </el-table-column>
            <el-table-column prop="targetCollectionIdList" label="生效藏品">
                <template slot-scope="{ row }">
                    <span v-for="(item, index) in row.targetCollectionIdList" :key="index">{{ item }}，</span>
                </template>
            </el-table-column>
            <el-table-column prop="targetCollectionId" label="是否有效">
                <template slot-scope="{ row }">
                    <span>{{ row.disabled == true ? '有效' : '无效' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="text" label="说明"> </el-table-column>

            <el-table-column prop="createdAt" label="创建时间"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <el-pagination background @size-change="onSizeChange" @current-change="onCurrentChange" :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalElements">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';
import resolveUrl from 'resolve-url';
import BaseUrl from '../utils/baseUrl';

export default {
    name: 'PreemptionWhiteList',
    mixins: [pageableTable],
    data() {
        return {
            headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
            },
            multipleMode: false,
            search: '', // 搜索字段
            url: '/userWareHouse/all', // 列表接口
            downloading: false,
            createdAt: '',
            uploadUrl: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    created() {
        this.uploadUrl = resolveUrl(BaseUrl, '/nft/userWareHouse/importBatch');
    },
    methods: {
        beforeGetData() {
            return {
                search: this.search,
                query: {
                    ItemName: 'CONSUMABLE_ASSIGN_CERTIFICATE,CONSUMABLE_CERTIFICATE',
                    del: false,
                    createdAt: this.createdAt
                }
            };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        // 新增
        addRow() {
            this.$router.push({
                path: '/preemptionWhiteEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        // 编辑
        editRow(row) {
            this.$router.push({
                path: '/preemptionWhiteEdit',
                query: {
                    id: row.id
                }
            });
        },
        handleProgress(files) {
            console.log(files)
        },
        uploadSuc() {
            this.getData();
        },
        // 导出
        download() {
            this.downloading = true;
            const params = {
                size: 10000,
                search: this.search,
                query: {
                    createdAt: this.createdAt,
                    projectId: this.$store.state.projectId
                }
            };
            this.$axios
                .post('/userWareHouse/excel', params, {
                    responseType: 'blob'
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', '白名单.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        // 列表 - 删除
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/userWareHouse/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
